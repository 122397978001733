<!--
 * @Author: your name
 * @Date: 2020-07-02 11:05:06
 * @LastEditTime: 2021-07-14 14:48:48
 * @LastEditors: Please set LastEditors
 * @Description: 搜索框控件
 * @FilePath: \user\src\views\home\myEn\components\readComm\viewSearch.vue
-->
<template>
  <div class="search-box">
    <en-icon v-if="inputflg && !noSearchDate" name="shijian-kongjian" size="small" style="color:#A9B5C6;display: inline-block;vertical-align: middle;" @click.native.stop="clickflg('inputflg')">
    </en-icon>
    <div class="search-input" v-if="!inputflg" @click.stop>
      <en-date-picker
        v-model="searchDate"
        :pickerOptions="{ shortcuts }"
        @change="searchChange"
        style="width:366px;height:32px;"
        type="daterange"
        range-separator="——"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        :editable="false"
        size="small"
        value-format="yyyy-MM-dd"
      >
      </en-date-picker>
    </div>
    <en-icon v-if="inputflg1" name="sousuo-danchuang" size="small" style="color:#A9B5C6;display: inline-block;vertical-align: middle;margin-left:20px;" @click.native.stop="clickflg('inputflg1')">
    </en-icon>
    <div class="search-input" v-if="!inputflg1" @click.stop>
      <el-input placeholder="请输入内容" v-model="input" @keyup.enter.native="searchChange" @change="searchChange">
        <i @click.stop="searchChange" slot="suffix" class="el-input__icon el-icon-search"></i>
      </el-input>
    </div>
  </div>
</template>

<script>
import Utils from "@/components/en-table-extra/data/utils";
import { enService } from "@/api/en/index";

export default {
  name: "Search",
  props: {
    show: {
      type: Boolean,
      default: true
    },
    noInput: {
      type: Boolean,
      default: false
    },
    noSearchDate: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      inputflg: true,
      inputflg1: true,
      input: "",
      searchDate: "",
      variableList: []
    };
  },
  watch: {
    show() {
      this.inputflg = true;
      this.inputflg1 = true;
    }
  },
  mounted() {
    this.queryRbacGloableVariableList();
  },
  computed: {
    shortcuts() {
      return this.variableList
        .filter((item) => item.id !== "0")
        .map((item) => ({
          text: item.name,
          onClick(picker) {
            const variableTime = Utils.getvariableTime(item);
            const end = new Date();
            const start = new Date();
            start.setTime(variableTime[0].getTime());
            end.setTime(variableTime[1].getTime());
            picker.$emit("pick", [start, end]);
          }
        }));
    }
  },
  methods: {
    async queryRbacGloableVariableList() {
      this.variableList = (await enService.queryRbacGloableVariableList({ type: 3 })) || [];
    },
    clickflg(item) {
      this[item] = !this[item];
    },
    searchChange() {
      const data = {
        input: this.input,
        searchDate: this.searchDate
      };
      this.$emit("toData", data);
    }
  }
};
</script>

<style lang="scss" scoped>
.search-input {
  width: 366px;
  display: inline-block;
  vertical-align: middle;
  margin-left: 10px;
}
</style>
